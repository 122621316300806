import { useState, useRef } from 'react';

function toggleDialog(dialogRef) {
	if(dialogRef.current === null || dialogRef.current === undefined)
		return;
	if(dialogRef.current.hasAttribute('open'))
		dialogRef.current.close();
	else
		dialogRef.current.showModal();
}

export function useModal(contentFn, cb) {
	let dialogRef = useRef(null);
	let [state, setState] = useState(undefined);
	let toggle = () => toggleDialog(dialogRef);
	let cb2 = (arg) => {toggle(); (cb ?? (() => {}))(arg ?? state)};
	return [(
		<dialog ref={dialogRef}>
			<a className="close" onMouseDown={() => toggle()}/>
			{contentFn(state, setState, cb2)}
		</dialog>
	), (s) => {setState(s); toggle();}];
}
