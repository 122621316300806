import {calculate} from './MyMath.js';
import { multinomialCoefficients} from './Multinomial.js';

function roll_int_dice(dice_size_int) {
	return Math.ceil(dice_size_int*Math.random());
}
function zip(a, b) {
	return a.map(function(e, i) {
		return [e, b[i]];
	});
}
export function combineHistograms(histoA, histoB) {
	if(histoA.length == 0)
		return histoB;
	if(histoB.length == 0)
		return histoA;
	let all = histoA.flatMap(a => histoB.map(b => new HistogramBin(a.bin + b.bin, (a.count * b.count))));
	return [...Map.groupBy(all, ({bin}) => bin)]
		.map(([k,v]) => new HistogramBin(k, v.reduce((ac, v) => ac + v.count, 0)));
}

export class DiceExpression {
	amount = 0;
	sides = 0;
	position = {begin: 0, end: 0};
	origString = '';
	selectiveResults = null;
	selectiveOp = null;
	selectiveN = null;

	CreateHistogram() {
		if(this.sides === 1)
			return [new HistogramBin(this.amount, 1)];
		function singleHisto(sides) {
			return [...Array(sides).fill(0).entries().map(([i]) => new HistogramBin(i+1, 1/sides))];
		}
		if(this.amount === 1)
			return singleHisto(this.sides);
		let op_drop_lowest = false;
		let selectiveN = this.selectiveN;
		if(!!this.selectiveOp) {
			op_drop_lowest = (this.selectiveOp[1] === 'l');
			if(this.selectiveOp[0] == 'k') {
				selectiveN = this.amount - selectiveN;
				op_drop_lowest = !op_drop_lowest;
			}
		}

		let {coefs: coefficients, counts} = multinomialCoefficients(this.amount, this.sides)
		let sumC = coefficients.reduce((a, i) => a + i, 0);
		if(op_drop_lowest) {
			for(let rArray of counts) {
				let n = selectiveN;
				for(let i in rArray) {
					while(n > 0 && rArray[i] > 0) {
						rArray[i]--;
						n--;
					}
					if(n===0) break;
				}
			}
		}
		else {
			for(let rArray of counts) {
				let n = selectiveN;
				for(let i = rArray.length-1; i >= 0; i--) {
					while(n > 0 && rArray[i] > 0) {
						rArray[i]--;
						n--;
					}
					if(n===0) break;
				}
			}
		}
		let rolls = counts.map(arr => arr.entries().reduce((ac, [i, c]) => ac + (c * (i+1)), 0));
		let histogram = Array.from(Map.groupBy(zip(rolls, coefficients), ([r]) => r).entries())
			.map(([r, c]) => [r,c.reduce((ac, b) => ac + b[1], 0)]);
		return histogram.map(([b, c]) => new HistogramBin(b, c / sumC));
	}
}

class HistogramBin {
	bin = 0;
	count = 0;
	constructor(b, c) {
		this.bin = b;
		this.count = c;
	}
	plus(other) {
		return new HistogramBin(this.bin + other.bin, this.count + other.count);
	}
	minus(other) {
		return new HistogramBin(this.bin - other.bin, this.count - other.count);
	}
}

export class Dice {
	dice_expressions = [];
	templateString = '';
	variables = {};
	rolls = [];
	rolls_used = [];
	calc = () => undefined;

	constructor(inputString) {
		this.inputString = inputString;
		this.templateString = inputString;
		this._parseIntoTemplate();
	}

	_parseIntoTemplate() {
		let re = /(?<amount>\d)+d((?<sides>\d+)(\s*(?<selOp>[kd][hl])\s*(?<selNum>\d+))?)?/gim;
		let dice = [];
		var match;
		while (match = re.exec(this.inputString)) {
			let d = new DiceExpression();
			d.position.begin = match.index;
			d.position.end = re.lastIndex;
			d.origString = this.inputString.slice(d.position.begin, d.position.end);
			d.amount = parseInt(match.groups.amount);
			d.sides = parseInt(match.groups.sides ?? '1');
			if(match.groups.selOp !== undefined) {
				let n = parseInt(match.groups.selNum);
				d.selectiveN = n;
				d.selectiveOp = match.groups.selOp;
				if(d.selectiveOp === 'kh')
					d.selectiveResults = x => x.slice(x.length - n);
				if(d.selectiveOp === 'kl')
					d.selectiveResults = x => x.slice(0, n);
				// todo other ops for dh dl.
			}
			dice.push(d);
		}
		this.dice_expressions = dice;
		let rev_dice = dice.filter(d => d.sides !== 1).slice(0).reverse();
		let i = rev_dice.length;
		for(let d of rev_dice) {
			this.templateString = this.templateString.substring(0, d.position.begin)
				+ `{dice_${i--}}`
				+ this.templateString.substring(d.position.end);
		}
		this.calc = calculate(this.templateString);
	}

	get histogram() {
		function myAdd(a, b) {
			if(typeof a === 'number' && typeof b === 'number')
				return a + b;
			if(typeof b === 'number')
				return a.map(v => ({...v, bin: v.bin + b}));
			if(typeof a === 'number')
				return b.map(v => ({...v, bin: v.bin + a}));
			console.log('add undefined');
			return combineHistograms(a, b);
		}
		function myNus(a, b) {
			if(typeof a === 'number' && typeof b === 'number')
				return a - b;
			if(typeof b === 'number')
				return a.map(v => ({...v, bin: v.bin - b}));
			if(typeof a === 'number')
				return b.map(v => ({...v, bin: v.bin - a}));
			console.log('minus undefined');
			return undefined;
		}
		function myTiply(a, b) {
			if(typeof a === 'number' && typeof b === 'number')
				return a * b;
			if(typeof b === 'number')
				return a.map(v => ({...v, bin: v.bin * b}));
			if(typeof a === 'number')
				return b.map(v => ({...v, bin: v.bin * a}));
			console.log('multiply undefined');
			return undefined;
		}
		try{
			let diceHistos = {};
			for(let [i, d] of this.dice_expressions.entries())
				diceHistos[`dice_${i + 1}`] = d.CreateHistogram();
			let st = {...this.variables, ...diceHistos, _add: myAdd, _minus: myNus, _multiply: myTiply};
			return this.calc(st);
		}catch(e){console.error(e);}
		return [];
	}

	roll_cb(fn) {
		let vars = structuredClone(this.variables);
		this.rolls = [];
		for(let [i, d] of this.dice_expressions.entries()) {
			let v = Array(d.amount).fill(0).map(_ => fn(d.sides));
			this.rolls.push(...v);
			v.sort((a, b) => a - b);
			if(d.selectiveResults !== undefined && d.selectiveResults !== null)
				v = d.selectiveResults(v);
			this.rolls_used.push(...v);
			if(v instanceof Array) {
				v = '(' + v.toString().replaceAll(',', '+') + ')';
			}
			vars['dice_' + (i + 1).toString()] = v;
		}
		let interpolated = this.templateString;
		for(let k of Object.keys(vars)) {
			var v = vars[k];
			interpolated = interpolated.replaceAll(`{${k}}`, v.toString());
		}
		try{
			return calculate(interpolated);
		}
		catch{return 0}
	}
	roll () {
		return this.roll_cb(x => roll_int_dice(x));
	}
	roll_max () {
		return this.roll_cb(x => x);
	}
	roll_array(values) {
		let i = 0;
		return this.roll_cb(_ => values[i++]);
	}
	chance_meet_or_beat (target) {
		if(target === undefined)
			target = 0;
		let success = this.histogram.reduce((c, h) => c + ((h.bin >= target) ? h.count : 0), 0);
		return success;
	}
}
